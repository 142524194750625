import "./App.css";

function App() {
  return (
    <div className="App">
      <br />
      webcake.com
      <br />
      <br />
      Custom Websites
    </div>
  );
}

export default App;
